import * as React from "react";
import { MapLibreMap } from "@mapcomponents/react-maplibre";
import "./App.css";
import Header from "./components/Header";
import "maplibre-gl/dist/maplibre-gl.css";
import NavigationTools from "./components/NavigationTools";
import Layers from "./components/Layers";
import Pages from "./components/Pages";

function App() {
  const backgroundAttribution =
    "© OpenStreetMap-Mitwirkende © Europäische Union, enthält Copernicus Sentinel-2 Daten 2021, verarbeitet durch das Bundesamt für Kartographie und Geodäsie (BKG)";
  return (
    <>
      <Header />
      <MapLibreMap
        mapId="map_1"
        options={{
          zoom: 6,
          style: {
            glyphs:
              "https://wms.wheregroup.com/tileserver/fonts/{fontstack}/{range}.pbf",
            version: 8,
            name: "blank",
            center: [0, 0],
            zoom: 0,
            sources: {},
            layers: [
              {
                id: "background",
                type: "background",
                paint: {
                  "background-color": "rgba(0,0,0,0)",
                },
              },
            ],
          },
          center: [13.620355523337707, 51.0410310937296],
          attributionControl: true,
          customAttribution: backgroundAttribution,
        }}
      />
      <Layers />
      <NavigationTools />
      <Pages />
    </>
  );
}

export default App;
