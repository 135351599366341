import React, { useContext } from "react";
import LayerSettingsContainer from "./LayerSettingsContainer";
import { Grid, Typography, Switch, Slider, Divider, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataContext } from "../../context/DataContext";
import { IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center", // Centers horizontally
  alignItems: "center", // Centers vertically
}));

export default function LayerSettings(props) {
  const dataContext = useContext(DataContext);

  const WidgetGrid = (props) => (
    <>
      <Grid item xs={props.width}>
        <StyledBox>{props.children}</StyledBox>
      </Grid>
    </>
  );

  const label = { inputProps: { "aria-label": "Fläche Fill zeigen" } };
  return (
    <>
      <LayerSettingsContainer
        extended={props.extended}
        setHeaderExtended={props.setHeaderExtended}
      >
        <Grid container alignItems="center" sx={{ paddingLeft: "25px", paddingRight: "10px" }}>
          {/* Flik layer Visibility */}
          <Grid item xs={9}>
            <Typography variant="body1">FLIK Fläche</Typography>
          </Grid>
          <WidgetGrid width={3}>
            <IconButton
              variant="contained"
              color="secondary"
              onClick={() => {
                dataContext.setFlikDataHidden(!dataContext.flikDataHidden);
              }}
            >
              {dataContext.flikDataHidden ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </IconButton>
          </WidgetGrid>

          {/* Flik layer fill */}
          {!dataContext.flikDataHidden && (
            <>
              <Grid item xs={9} alignContent={"center"}>
                <Typography variant="body2" sx={{ marginLeft: "10px" }}>
                  Füllung der Fläche
                </Typography>
              </Grid>
              <WidgetGrid width={3}>
                <Switch
                  color="secondary"
                  {...label}
                  checked={dataContext.showFlikDataFill}
                  onChange={() =>
                    dataContext.setShowFlikDataFill(
                      !dataContext.showFlikDataFill
                    )
                  }
                />
              </WidgetGrid>
            </>
          )}

          {/* Flik layer transparency */}
          {!dataContext.flikDataHidden && dataContext.showFlikDataFill && (
            <>
              <Grid item xs={6} alignContent={"center"}>
                <Typography variant="body2" sx={{ marginLeft: "10px" }}>
                Deckkraft
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Slider
                  aria-label="opacity"
                  color="secondary"
                  value={dataContext.flikDataOpacity ?? 0.4}
                  min={0}
                  max={1}
                  step={0.1}
                  valueLabelDisplay="auto"
                  onChange={(ev, val) =>
                    dataContext.setFlikDataOpacity(val)
                  }
                />
              </Grid>
            </>
          )}

          {/* ProtectedArea layer Visibility */}
          <Divider
            sx={{
              border: "1px inset rgb(55,55,55)",
              borderRadius: 1,
              width: "90%",
            }}
          />
          <Grid item xs={9}>
            <Typography variant="body1">Schutzgebiete</Typography>
          </Grid>
          <WidgetGrid width={3}>
            <IconButton
              color="secondary"
              variant="contained"
              onClick={() => {
                dataContext.setProtectedAreaHidden(
                  !dataContext.protectedAreaHidden
                );
              }}
              sx={props.sx}
            >
              {dataContext.protectedAreaHidden ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </IconButton>
          </WidgetGrid>

          {/* ProtectedAreas transparency */}
          {!dataContext.protectedAreaHidden && (
            <>
              <Grid item xs={6} alignContent={"center"}>
                <Typography variant="body2" sx={{ marginLeft: "10px" }}>
                Deckkraft
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Slider
                  aria-label="opacity"
                  color="secondary"
                  value={dataContext.protecteAreasOpacity ?? 0.6}
                  min={0}
                  max={1}
                  step={0.1}
                  valueLabelDisplay="auto"
                  onChange={(ev, val) =>
                    dataContext.setProtecteAreasOpacity(val)
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </LayerSettingsContainer>
    </>
  );
}
