import React, { useContext } from "react";
import { Typography, Grid } from "@mui/material";
import { DataContext } from "../context/DataContext";

export default function FeaturePreview(props) {
  const dataContext = useContext(DataContext);
  const info = props.feature.properties

  return (
    <>
      {props.feature?.properties && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6"> FLIK-Nummer </Typography>
            <Typography variant="body1">
              {info.feldblockidentnummer}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6"> Bodennutzungsart </Typography>
            <Typography variant="body1">
              {info.bodennutzungstyp} {dataContext.glossar?.[info.land]?.[info.bodennutzungstyp]
                ? ("(" + dataContext.glossar[info.land][info.bodennutzungstyp] + ")")
                : info.bodennutzungstyp
                ? info.bodennutzungstyp
                : "Nicht bekannt"}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const keyTitels = ["Flächengröße", "FLIK-Nummer"];
