import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const DataContext = React.createContext({});

const AppStateProvider = DataContext.Provider;

const DataContextProvider = ({ children }) => {


  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedFromList, setSelectedFromList] = useState();
  const [loading, setLoading] = useState(false);
  const [flikDataHidden, setFlikDataHidden] = useState(false);
  const [showFlikDataFill, setShowFlikDataFill] = useState(true);
  const [flikDataOpacity, setFlikDataOpacity] = useState(0.4)
  const [protectedAreaHidden, setProtectedAreaHidden] = useState(false);
  const [protecteAreasOpacity, setProtecteAreasOpacity] = useState(0.6)
  const [glossar, setGlossar] = useState();

  //load landuse Glossar
useEffect(() => {
    fetch("./glossar.json").then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((json)=> setGlossar(json));
  }, []);

  const value = {
    selectedFromList,
    setSelectedFromList,
    selectedFeatures,
    setSelectedFeatures,
    loading,
    setLoading,
    flikDataHidden,
    setFlikDataHidden,
    showFlikDataFill,
    setShowFlikDataFill,
    flikDataOpacity, 
    setFlikDataOpacity,
    protectedAreaHidden,
    setProtectedAreaHidden,
    protecteAreasOpacity, 
    setProtecteAreasOpacity,
    glossar
  };

  return <AppStateProvider value={value}>{children}</AppStateProvider>;
};

DataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DataContext, DataContextProvider };