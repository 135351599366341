import React, { useState, useContext, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";
import { useTheme } from "@mui/material/styles";
import { defaultTheme } from "../index";
import { DataContext } from "../context/DataContext";

export default function Legend(props) {
  const dataContext = useContext(DataContext);
  const theme = useTheme(defaultTheme);
  const [layers, setLayers] = useState([
    { name: "FLIK Fläche", active: true },    
    { name: "Schutzgebiete", color: "protectedAreas", active: true },
  ]);

  const allLayersInactive = layers.every((layer) => !layer.active);

  useEffect(() => {
    setLayers((current) => {
      const newState = [...current];
  
      if (dataContext?.flikDataHidden !== undefined) {
        const flikDataActive = !dataContext.flikDataHidden;
        newState[0].active = flikDataActive;
        
      }
  
      if (dataContext?.protectedAreaHidden !== undefined) {
        newState[1].active = !dataContext.protectedAreaHidden;
      }
  
      return newState;
    });
  }, [dataContext?.flikDataHidden, dataContext?.protectedAreaHidden]);

  return (
    <>
    {!allLayersInactive && <Box
        sx={{
          position: "absolute",
          backgroundColor: "rgba(55,55,55,0.9)",
          zIndex: 1500,
          right: "80px",
          bottom: "20px",
          width: "180px",
          padding: "10px 15px 10px;",
        }}
      >
        <Grid container >
          {layers?.map(
            (layer) =>           
            layer.name !== "FLIK Fläche" ? (
                layer.active && 
               <>
                  <Grid item xs={2}>
                    <SquareIcon
                      sx={{ color: theme.palette.layers[layer.color] }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    
                  </Grid>
                  <Grid item xs={8}>
                    <Typography varian="body2" color="secondary">
                      {layer.name}
                    </Typography>
                  </Grid>
                </>
              ) : (layer.active && 
                <>
                  <Grid item xs={2}>
                    <SquareIcon
                      sx={{ color: theme.palette.layers.apt }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SquareIcon
                      sx={{ color: theme.palette.layers.not_apt }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography varian="body2" color="secondary">
                      {layer.name}
                    </Typography>
                  </Grid>
                </>
              ) 

          )}
        </Grid>
      </Box> }
     
    </>
  );
}
