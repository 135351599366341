import * as React from "react";
import { AppBar, Box, Typography, IconButton, Divider } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import "simplebar-react/dist/simplebar.min.css";

export default function LayerSettingsContainer(props) {
  return (
    <>
      <AppBar
        position="fixed"
        id="find_me"
        elevation={0}
        style={{
          paddingTop: "74px",
          paddingBottom: "10px",
          backgroundColor: "rgba(55,55,55,0.9)",
          zIndex: 1050,
          height: props.extended ? "auto" : "144px",
          width: props.extended ? "300px" : "125px",
          //transition: "transform 0.5s ease, opacity 0.5s ease", // Smooth transform and opacity
        }}
      >
        
          {props.extended ? (
            <Typography variant="h5" sx={{ margin: "20px 0 0 180px" }}>
              Layers{" "}
            </Typography>
          ) : (
            <Typography
              variant="h5"
              sx={{
                margin: "20px 0 0 35px",
              }}
            >
              Layers
            </Typography>
          )}
          <IconButton
            style={{
              position: "absolute",
              marginTop: "15px",
              marginLeft: "1px",
            }}
            edge="start"
            color="inherit"
            onClick={(ev) => {
              props.setHeaderExtended(!props.extended);
            }}
          >
            {props.extended ? (
              <ArrowForwardIosIcon style={{ width: "30px" }} />
            ) : (
              <ArrowBackIosNewIcon />
            )}
          </IconButton>
       

        {props.extended && (
          <>
            <Box sx={{  marginTop: "20px" }}>
              {props.children}
            </Box>
          </>
        )}
      </AppBar>
    </>
  );
}
