import React, { useEffect, useRef, useContext } from "react";
import {
  MlVectorTileLayer,
  useMap,
} from "@mapcomponents/react-maplibre";
import { useState } from "react";
import Sidebar from "./Sidebar";
import BottomSidebar from "./BottomSidebar";
import FeatureInfo from "./FeatureInfo";
import useLayerEvent from "../hooks/useLayerEvent";
import useWindowSize from "../hooks/useWindowSize";
import InfoPopper from "./infoPoper";
import config from "../config";
import { DataContext } from "../context/DataContext";
import { SpinnerCircularFixed } from "spinners-react";
import { SearchContext } from "../context/SearchContext";
import { useTheme } from "@mui/material/styles";
import {defaultTheme} from "../index";

export default function DataLayer() {
  const mapHook = useMap({ mapId: "map_1" });
  const dataContext = useContext(DataContext);
  const searchContext = useContext(SearchContext);
  const [hoveredFeature, setHoveredFeature] = useState();
  const hoveredStateId = useRef(undefined);
  const theme = useTheme(defaultTheme);

  useLayerEvent({
    event: "click",
    layerId: "flik-data_sachs",
    condition: !dataContext.flikDataHidden,
    eventHandler: (ev) => {
      if (ev.originalEvent.ctrlKey && ev?.features?.[0]) {
        let featureState = mapHook.map.getFeatureState(ev.features[0]);
        if (featureState?.selected === true) {
          let newSelectedFeatures = [];
          dataContext.setSelectedFeatures((current) => {
            newSelectedFeatures = current.filter(
              (el) => el.id !== ev.features[0].id
            );
            return newSelectedFeatures;
          });
          mapHook.map.setFeatureState(
            {
              sourceLayer: "sql_statement",
              source: "flik-data",
              id: ev.features[0].id,
            },
            { selected: false }
          );

          if (
            dataContext.selectedFromList === ev.features[0].id &&
            newSelectedFeatures.length
          ) {
            dataContext.setSelectedFromList(newSelectedFeatures[0].id);
            mapHook.map.setFeatureState(
              {
                sourceLayer: "sql_statement",
                source: "flik-data",
                id: newSelectedFeatures[0].id,
              },
              { selected: true }
            );
          }
        } else {
          dataContext.setSelectedFeatures((current) => {
            return [...current, ev.features[0]];
          });

          mapHook.map.setFeatureState(
            {
              sourceLayer: "sql_statement",
              source: "flik-data",
              id: ev.features[0].id,
            },
            { selected: true }
          );
          dataContext.setSelectedFromList(ev.features[0].id);
        }
      } else {
        dataContext.selectedFeatures.forEach((element) => {
          mapHook.map.setFeatureState(
            {
              sourceLayer: "sql_statement",
              source: "flik-data",
              id: element.id,
            },
            { selected: false, fromListSelected: false }
          );
        });
        dataContext.setSelectedFeatures([ev.features[0]]);
        dataContext.setSelectedFromList(ev.features[0].id);
        mapHook.map.setFeatureState(
          {
            sourceLayer: "sql_statement",
            source: "flik-data",
            id: ev.features[0].id,
          },
          { selected: true }
        );
      }
    },
  });

  useEffect(() => {
    if (!mapHook.map) return;

    dataContext.selectedFeatures.forEach((element) => {
      mapHook.map.setFeatureState(
        {
          sourceLayer: "sql_statement",
          source: "flik-data",
          id: element.id,
        },
        { fromListSelected: false }
      );
    });
    mapHook.map.setFeatureState(
      {
        sourceLayer: "sql_statement",
        source: "flik-data",
        id: dataContext.selectedFromList,
      },
      { fromListSelected: true }
    );
    // eslint-disable-next-line
  }, [dataContext.selectedFromList, mapHook.map]);

  useLayerEvent({
    event: "mousemove",
    layerId: "flik-data_sachs",
    condition: !dataContext.flikDataHidden,
    eventHandler: function (e) {
      if (e.features[0].id) {
        setHoveredFeature(e.features[0]);
        if (
          hoveredStateId.current &&
          e.features[0].id !== hoveredStateId.current
        ) {
          mapHook.map.setFeatureState(
            {
              sourceLayer: "sql_statement",
              source: "flik-data",
              id: hoveredStateId.current,
            },
            { hover: false }
          );
        }
        hoveredStateId.current = e.features[0].id;

        mapHook.map.setFeatureState(
          {
            sourceLayer: "sql_statement",
            source: "flik-data",
            id: hoveredStateId.current,
          },
          { hover: true }
        );
      }
    },
  });

  useLayerEvent({
    event: "mouseleave",
    layerId: "flik-data_sachs",
    condition: !dataContext.flikDataHidden,
    eventHandler: function (e) {
      if (hoveredStateId.current) {
        mapHook.map.setFeatureState(
          {
            sourceLayer: "sql_statement",
            source: "flik-data",
            id: hoveredStateId.current,
          },
          { hover: false }
        );
      }
      hoveredStateId.current = undefined;
      setHoveredFeature(undefined);
    },
  });

  const size = useWindowSize();

  const [headerExtended, setHeaderExtended] = useState(true);

  useEffect(() => {
    setHeaderExtended(true);
  }, [dataContext.selectedFeatures]);

  useEffect(() => {
    if (searchContext.searchResult) {
      dataContext.selectedFeatures.forEach((element) => {
        mapHook.map.setFeatureState(
          {
            sourceLayer: "sql_statement",
            source: "flik-data",
            id: element.id,
          },
          { selected: false, fromListSelected: false }
        );
      });
      const elementsToAdd = [];
      searchContext.searchResult.forEach((element) => {
        if (element.properties.feldblockidentnummer) {
          elementsToAdd.push(element);
          mapHook.map.setFeatureState(
            {
              sourceLayer: "sql_statement",
              source: "flik-data",
              id: element.id,
            },
            { selected: true }
          );
        }
      });
      dataContext.setSelectedFeatures(elementsToAdd);
    }
    // eslint-disable-next-line
  }, [searchContext.searchResult]);

  return (
    <>
      {dataContext.loading && (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2000,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          {" "}
          <SpinnerCircularFixed secondaryColor="#fff" color="#2071b5" />
        </div>
      )}
      <MlVectorTileLayer
        mapId="map_1"
        layerId="flik-data"
        url={
          config.tileServerHost + "tileservergl/data/flik_brd/{z}/{x}/{y}.pbf?"
        }
        insertBeforeLayer="marker-data"
        sourceOptions={{ maxZoom: 16, minZoom: 8, promoteId: "gid" }}
        layers={{
          sachs: {
            type: "fill",
            "source-layer": "sql_statement",
            paint: {
              "fill-color": [
                "case",
                ["==", ["get", "geeignet"], true],
                theme.palette.layers?.apt,
                theme.palette.layers?.not_apt,
              ],

              "fill-opacity": dataContext.flikDataHidden || !dataContext.showFlikDataFill
                ? 0
                : [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    0.1,

                    [
                      "case",
                      ["boolean", ["feature-state", "selected"], false],
                      0,
                      dataContext.flikDataOpacity,
                    ],
                  ],
            },
            maxzoom: 22,
            minzoom: 8,
          },
          lines: {
            type: "line",
            "source-layer": "sql_statement",
            paint: {
              "line-width": [
                "case",
                ["==", ["feature-state", "selected"], true],
                [
                  "case",
                  ["==", ["feature-state", "fromListSelected"], true],
                  5,
                  3,
                ],
                1,
              ],
              "line-opacity": dataContext.flikDataHidden
                ? 0
                : ["interpolate", ["exponential", 1], ["zoom"], 8, 0, 14, 1],
              "line-color": [
                "case",
                ["==", ["feature-state", "selected"], true],
                [
                  "case",
                  ["==", ["feature-state", "fromListSelected"], true],
                  theme.palette.layers?.line_list_selected,
                  theme.palette.layers?.line_list_unselected,
                ],

                [
                  "case",
                  ["==", ["get", "geeignet"], true],
                  "darkblue",
                  "darkred",
                ],
              ],
            },
          },
        }}
      />

      {size.width > 980 && !dataContext.flikDataHidden && (
        <InfoPopper feature={hoveredFeature} hovered={!!hoveredFeature} />
      )}

      {dataContext.selectedFeatures[0] && !dataContext.flikDataHidden && (
        <>
          {size.width > 600 && (
            <>
              <Sidebar
                extended={headerExtended}
                setHeaderExtended={setHeaderExtended}
              >
                <FeatureInfo feature={dataContext.selectedFeatures} />
              </Sidebar>
            </>
          )}
          {size.width < 600 && (
            <>
              <BottomSidebar>
                <FeatureInfo feature={dataContext.selectedFeatures} />
              </BottomSidebar>
            </>
          )}
        </>
      )}
    </>
  );
}
