import React, {useState} from 'react'
import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LayerSettings from './LayerSettings';
import { useMap } from "@mapcomponents/react-maplibre";

export default function LayerSettingsButton(props) {
 
  const [open, setOpen] = useState(false);
  const mapHook = useMap({ mapId: "map_1" });


  return (
    <>
     <IconButton
        color="primary"
        variant={open? "contained": "outlined"}
        onClick={() => {
          setOpen(!open)
        }}
        sx={props.sx}
      >
  <VisibilityIcon color={open ? "primary": "#ffffff"}/>
      </IconButton>
{ mapHook.map?.map.getZoom() >= 8 &&  <LayerSettings extended={open} setHeaderExtended={setOpen} /> }

    </>
        
  )
}