import React from "react";
import useWindowSize from "../hooks/useWindowSize";
import { MlNavigationTools } from "@mapcomponents/react-maplibre";
import MlCreatePdfButton from "./CreatePdfButton/MlCreatePdfButton";
import LayerSettingsButton from "./LayerSettingsButton/LayerSettingsButton.jsx";

const Buttons = ({ sx }) => {
  return (
    <>
      <MlCreatePdfButton sx={sx} />
      <LayerSettingsButton sx={sx} />
    </>
  );
};

function NavigationTools() {
  const size = useWindowSize();

  return (
    <>
      {size.width > 600 && (
        <MlNavigationTools mapId="map_1">
          <Buttons />
        </MlNavigationTools>
      )}
    </>
  );
}

export default NavigationTools;
