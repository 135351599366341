import React, { useState, useEffect, useContext, useMemo } from "react";
import List from "@mui/material/List";
import ListItem from "./ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Typography, ListItemButton, Box } from "@mui/material";
import config from "../config";
import { useMap } from "@mapcomponents/react-maplibre";
import { DataContext } from "../context/DataContext";
import Downloader from "./Downloader";


export default function FeatureInfo(props) {
  const mapHook = useMap({ mapId: "map_1" });
  const dataContext = useContext(DataContext);
  const [area, setArea] = useState();
  const [matchingProtectedAreas, setMatchingProtectedAreas ] = useState()
  const [description, setDescription] = useState();

  const info = useMemo(() => {
    const feature = dataContext.selectedFeatures.find(
      (el) => el.id === dataContext.selectedFromList
    );

    if (!feature?.properties) return {};

    return feature.properties;
  }, [dataContext.selectedFromList, dataContext.selectedFeatures]);

  useEffect(() => {
    if (!dataContext.selectedFromList) return;

    const selectedFeature = dataContext.selectedFeatures.find(
      (el) => el.id === dataContext.selectedFromList
    );
    if (!selectedFeature) return;
    // get Feld Area
    setArea("wird berechnet ...");
    setDescription("");
  
    fetch(config.backendOrigin + "api/fid/area/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fid: selectedFeature.properties.feldblockidentnummer,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return false;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        if (data[0]) {
          setArea((data[0] * 0.0001).toFixed(2) + " ha");
          if (data[1]) {
            setDescription(data[1]);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

        // GET Protected Areas

setMatchingProtectedAreas("wird geprüft...");

fetch(config.backendOrigin + "api/protected/" + selectedFeature.properties.feldblockidentnummer , {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },  
})
  .then((res) => {
    if (!res.ok) {
      return false;
    } else {
      return res.json();
    }
  })
  .then((data) => {
    setMatchingProtectedAreas(data.result)
  })
  .catch((error) => {
    setMatchingProtectedAreas("Fehler bei der Überprüfung")
    console.error("Error:", error);
  });

      // eslint-disable-next-line
  }, [dataContext.selectedFromList]);

  const hoverPreview = (feature) => {
    mapHook.map.setFeatureState(
      {
        sourceLayer: "sql_statement",
        source: "flik-data",
        id: feature.gid,
      },
      { hover: true }
    );
  };

  const hoverLeave = (feature) => {
    mapHook.map.setFeatureState(
      {
        sourceLayer: "sql_statement",
        source: "flik-data",
        id: feature.gid,
      },
      { hover: false }
    );
  };


  return (
    <>
      {props.feature && (
        <List
          disablePadding={true}
          sx={{
            width: "90%",
            backgroundColor: "rgba(55,55,55,0.1)",
            margin: "0px auto",
            padding: "5px 0px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "5px",
            }}
          >
            Ausgewählte Flächen: {props.feature.length}
          </Typography>
          <Box
            sx={{
              maxHeight: "185px",
              overflow: "auto",
              marginBottom: "5px",
              padding: "20px auto",
            }}
          >
            {props.feature.map((feature) => (
              <>
                {feature?.properties?.feldblockidentnummer && (
                  <ListItemButton
                    key={feature.id}
                    sx={{
                      backgroundColor:
                        dataContext.selectedFromList === feature.id
                          ? "rgba(99,99,99,1)"
                          : "rgba(33,33,33,1)",
                      padding: "4px 15px",
                      marginBottom: "5px",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      dataContext.setSelectedFromList(feature.id);
                    }}
                    onMouseOver={() => hoverPreview(feature.properties)}
                    onMouseLeave={() => hoverLeave(feature.properties)}
                  >
                    <Typography>
                      {feature.properties.feldblockidentnummer}
                    </Typography>
                  </ListItemButton>
                )}
              </>
            ))}
          </Box>

          {info && (
            <>
              <ListItem divider={true}>
                <ListItemText
                  primary={<Typography variant="h6">FLIK-Nummer</Typography>}
                  secondary={
                    <Typography variant="body2">
                      {info.feldblockidentnummer}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem divider={true}>
                <ListItemText
                  primary={
                    <Typography variant="h6">Bodennutzungsart</Typography>
                  }
                  secondary={
                    <Typography variant="body2">
                      {info.bodennutzungstyp}{" "}{ dataContext.glossar?.[info.land]?.[info.bodennutzungstyp] ? ("(" + dataContext.glossar?.[info.land]?.[info.bodennutzungstyp] + ")") : (info.bodennutzungstyp? info.bodennutzungstyp : "Nicht bekannt" ) }
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem divider={true}>
                <ListItemText
                  primary={<Typography variant="h6">Flächengröße</Typography>}
                  secondary={<Typography variant="body2">{area}</Typography>}
                />
              </ListItem>
              <ListItem divider={true}>
                <ListItemText
                  primary={<Typography variant="h6"> Jahr </Typography>}
                  secondary={
                    <Typography variant="body2">{info.jahr}</Typography>
                  }
                />
              </ListItem>
              <ListItem divider={true}>
                <ListItemText
                  primary={<Typography variant="h6"> Land </Typography>}
                  secondary={
                    <Typography variant="body2">{info.land}</Typography>
                  }
                />
              </ListItem>
              {description ? (
                <ListItem divider={true}>
                  <ListItemText
                    primary={<Typography variant="h6"> Bemerkung </Typography>}
                    secondary={
                      <Typography variant="body2">{description}</Typography>
                    }
                  />
                </ListItem>
              ) : (
                ""
              )}
              {info.flaeche && (
                <ListItem divider={true}>
                  <ListItemText
                    primary={<Typography variant="h6"> Größe </Typography>}
                    secondary={info.flaeche}
                  />
                </ListItem>
              )}
              <ListItem divider={true}>
                <ListItemText
                  primary={<Typography variant="h6"> gid </Typography>}
                  secondary={
                    <Typography variant="body2">{info.gid}</Typography>
                  }
                />
              </ListItem>
              <ListItem divider={true}>
                <ListItemText
                  primary={<Typography variant="h6"> Schutzgebiete </Typography>}
                  secondary={
                    <Typography variant="body2">{matchingProtectedAreas}</Typography>
                  }
                />
              </ListItem>
            </>
          )}
          <Downloader features={props.feature} />
        </List>
      )}
    </>
  );
}
