import React, {useContext } from "react";
import { MlVectorTileLayer } from "@mapcomponents/react-maplibre";
import config from "../config";
import { DataContext } from "../context/DataContext";
import { useTheme } from "@mui/material/styles";
import {defaultTheme} from "../index";

const protectedAreas = [
    "Fauna_Flora_Habitat_Gebiete",
    "Nationale_Naturmonumente",
    "Biosphaerenreservate",
    "Nationalparke",
    "Naturschutzgebiete",
    "Vogelschutzgebiete",
    "Landschaftsschutzgebiete",
];

export default function ProtectedAreas() {
  const dataContext = useContext(DataContext);
  const theme = useTheme(defaultTheme);

  const layerConfigs = () => {
    const layerConfig = {};
   
    protectedAreas.forEach(
      (area) =>{
        layerConfig[area] = {
          type: "fill",
          "source-layer": area,
          paint: {
            "fill-color": theme.palette.layers?.protectedAreas,
            "fill-opacity": dataContext.protectedAreaHidden ? 0 : dataContext.protecteAreasOpacity,
          },
          maxzoom: 22,
          minzoom: 8,
        };

        layerConfig[area + "_label"] = {
            type: "symbol",
            "source-layer": area,
            paint: { "text-opacity": dataContext.protectedAreaHidden ? 0 : dataContext.protecteAreasOpacity },
            layout: {             
                "text-field": area.replace(/_/g, " "),
                "text-font": ["Klokantech Noto Sans Regular"],
            },
            maxzoom: 22,
            minzoom: 11,
        }

    }
    )

    ;
    return layerConfig;
  };
  return (
    <>
      <MlVectorTileLayer
        mapId="map_1"
        layerId="Schutzgebiete"
        url={
          config.tileServerHost +
          "tileservergl/data/shuetzgebiete/{z}/{x}/{y}.pbf?"
        }
        insertBeforeLayer="flik-data_sachs"        
        sourceOptions={{ attribution: "© Bundesamt für Naturschutz- Stand der Daten: 2024", maxZoom: 16, minZoom: 8 }}
        layers={layerConfigs()}
      />
    </>
  );
}
